<template>
	<v-card>
		<v-toolbar color="primary">
			<v-toolbar-title class="white--text"
				>Crear incidencia {{ identidad }}</v-toolbar-title
			>
			<v-spacer></v-spacer>
			<v-btn color="white" icon @click="$emit('close')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>
		<v-form @submit.prevent="crearIncidencia" ref="form">
			<v-container class="pa-2 mt-8">
				<v-row>
					<v-col cols="12" md="4">
						<v-text-field
							hide-details
							outlined
							:value="identidad"
							readonly
							label="Cliente"
						/>
					</v-col>
					<v-col cols="12" md="4">
						<v-autocomplete
							hide-details="auto"
							outlined
							label="CUPS"
							clearable
							:items="cupsItems"
							v-model="cups"
							:rules="[rules.req]"
						/>
					</v-col>
					<v-col cols="12" md="4">
						<v-autocomplete
							hide-details="auto"
							outlined
							label="Grupo"
							clearable
							:items="['Clientes', 'Sistema', 'Impagados', 'Otras']"
							v-model="tipo"
							:rules="[rules.req]"
						/>
					</v-col>
					<v-col cols="12">
						<text-editor
							v-model="mensaje"
							:error="emptyMsg"
							@input="(v) => (emptyMsg = !v)"
							class="mb-2"
						/>
					</v-col>
					<v-col cols="12">
						<div class="d-flex">
							<v-spacer />
							<v-btn outlined color="primary" @click="crearIncidencia">
								<v-icon left>mdi-content-save</v-icon>
								Crear Incidencia
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
	</v-card>
</template>

<script>
import { decode64 } from "@/utils/index.js";
import { req } from "@/utils/validations.js";

export default {
	components: {
		TextEditor: () => import("@/components/TextEditor.vue"),
	},
	props: {
		idCliente: String,
		identidad: String,
	},
	mounted() {
		if (!this.idCliente) return;

		axios({
			method: "GET",
			url: `${process.env.VUE_APP_API_URL}/cups.php`,
			params: {
				token: this.$store.getters.getToken,
				IdCliente: this.idCliente,
			},
		}).then(({ data }) => {
			this.cupsItems = data.map((c) => c.CodigoCups);
			// this.cups = this.cupsItems[0];
		});
	},
	data() {
		return {
			cups: null,
			tipo: null,
			incidencia: null,
			mensaje: "",
			emptyMsg: false,
			cupsItems: [],
			rules: {
				req,
			},
		};
	},
	methods: {
		crearIncidencia() {
			if (this.mensaje.length == 0) {
				this.emptyMsg = true;
				return;
			}
			if (!this.$refs.form.validate()) return;

			let { idUsuario, empresa } = JSON.parse(
				decode64(this.$store.getters.getToken.split(".")[1])
			);

			axios({
				method: "POST",
				url: `${process.env.VUE_APP_API_URL}/incidencias.php`,
				data: {
					token: this.$store.getters.getToken,
					idCliente: this.idCliente,
					dniCliente: this.identidad,
					cupsCliente: this.cups,
					idUsuario,
					mensaje: this.mensaje,
					estado: "Abierta",
					fecha: new Date().getTime(),
					grupo: this.tipo,
					empresa
				},
			})
				.then((res) => {
					console.log(res.data);
					this.$emit("close");
					this.$root.$emit('getAlerts');
				})
				.catch((err) => {
					console.error(err);
					this.$root.$emit(err);
				});
		},
	},
};
</script>

<style>
</style>